// Adds a module to a vuex store with a set of media query states

const defaultOptions = {
  module: "mediaQueries"
};

// Hard coded values taken directly from Bulma css
const mediaQueries = {
  mobile: "screen and (max-width: 768px)",
  tablet: "screen and (min-width: 769px)",
  tabletOnly: "screen and (min-width: 769px) and (max-width: 1023px)",
  touch: "screen and (max-width: 1023px)",
  desktop: "screen and (min-width: 1024px)",
  desktopOnly: "screen and (min-width: 1024px) and (max-width: 1215px)",
  widescreen: "screen and (min-width: 1216px)",
  widescreenOnly: "screen and (min-width: 1216px) and (max-width: 1407px)",
  fullhd: "screen and (min-width: 1408px)"
};

export default function(store, options) {
  let opts = Object.assign({}, defaultOptions, options || {});
  const moduleName = opts.module;

  const initialState = {};

  for (let device in mediaQueries) {
    const query = window.matchMedia(mediaQueries[device]);
    query.onchange = (q) => {
      store.commit(moduleName + "/MEDIA_QUERY_CHANGED", {mediaName: device, value: q.matches});
    };
    initialState[device] = query.matches;
  }

  store.registerModule(moduleName, {
    namespaced: true,
    state: initialState,
    mutations: {
      "MEDIA_QUERY_CHANGED" (state, data) {
        state[data.mediaName] = data.value;
      }
    }
  });

}