import Vue from "vue";

function setATargets(el) {
  const anchors = el.getElementsByTagName("a");

  [...anchors]
    .filter(a => a.host !== window.location.host)
    .forEach(a => {
      a.target = "_blank";
      a.ref = "noopener";
    });

}

Vue.directive("external-links", {
  bind(el) {
    setATargets(el);
  },

  componentUpdated(el) {
    setATargets(el);
  }
});