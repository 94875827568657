import Vue from 'vue'
import FontAwesomeIcon from '@fortawesome/vue-fontawesome';
import fontawesome from '@fortawesome/fontawesome';
import {
  faAngleDown,
  faCheckSquare,
  faCircle,
  faClipboard,
  faFile,
  faChevronLeft,
  faChevronRight,
  faExclamationTriangle,
  faExpand,
  faMinusSquare,
  faPencilAlt,
  faSearch,
  faSpinner,
  faSquare,
  faTimes,
  faToggleOn
} from '@fortawesome/fontawesome-free-solid';

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

fontawesome.library.add(
  faAngleDown,
  faCheckSquare,
  faCircle,
  faClipboard,
  faFile,
  faChevronLeft,
  faChevronRight,
  faExclamationTriangle,
  faExpand,
  faMinusSquare,
  faPencilAlt,
  faSearch,
  faSpinner,
  faSquare,
  faTimes,
  faToggleOn
);
