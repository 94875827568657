import * as Errors from "./Errors";

const IntSerializer = {
  serialize(i) {
    if (i !== null) {
      return i.toString();
    } else {
      return "";
    }
  },

  deserialize(data) {
    return parseInt(data);
  }
};

const IdArraySerializer = {
  serialize(arr) {
    return arr.join(",");
  },

  deserialize(data) {
    return data.split(",").map(d => parseInt(d));
  }
};

const SubpopulationIdSerializer = {
  serialize(subpops) {
    return subpops.map(s => `${s.variableId}!${s.categoryIds.join(".")}`).join(",")
  },

  deserialize(data) {
    return data.split(",").map(s => {
      let parts = s.split("!");
      return {
        variableId: parseInt(parts[0]),
        categoryIds: parts[1].split(".").map(c => parseInt(c))
      }
    });
  }
};

const BooleanSerializer = {
  serialize(val) {
    return val ? "t" : "f";
  },

  deserialize(data) {
    return data === "t";
  }
};

const StringSerializer = {
  serialize(val) {
    return val;
  },

  deserialize(data) {
    return data;
  }
};

const CURRENT_VERSION = 1;

const VERSIONS = {
  1: {
    variableIds: IdArraySerializer,
    sampleIds: IdArraySerializer,
    subpopulationIds: SubpopulationIdSerializer,
    placeId: IntSerializer,
    showGraphs: BooleanSerializer,
    measure: StringSerializer
  }
};

class SessionSerializer {
  constructor() {
    this.version = CURRENT_VERSION;
    this.variableIds = [];
    this.sampleIds = [];
    this.subpopulationIds = [];
    this.showGraphs = true;
    this.measure = null;
    this.placeId = null;
  }

  serialize() {
    const values = [];
    const version = VERSIONS[this.version];

    if (!version) {
      throw new Errors.SessionSerializationError("Unknown version: " + this.version);
    }

    values.push(this.version);

    for (let key in version) {
      let serializer = version[key];
      values.push(serializer.serialize(this[key]));
    }

    return this.constructor.encode(values.join("~"));
  }

  static fromState(state) {
    const serializer = new SessionSerializer();
    serializer.variableIds = state.selectedVariables.map(v => v.id);
    serializer.sampleIds = state.selectedSamples.map(s => s.id);
    serializer.subpopulationIds = state.selectedSubpopulations.map(s => ({ variableId: s.variable.id, categoryIds: s.selectedCategories.map(c => c.id)}));
    serializer.placeId = state.selectedPlace !== null && state.selectedPlace.id !== -1 ? state.selectedPlace.id : null;
    serializer.showGraphs = state.dataDisplayOptions.showGraphs;
    serializer.measure = state.dataDisplayOptions.measure.value;
    return serializer;
  }

  static fromSerializedData(data) {
    if (!data) {
      throw new Errors.SessionSerializationError("Empty session data");
    }

    const serializer = new SessionSerializer();

    data = this.decode(data);
    const values = data.split("~");

    if (values.length === 0) {
      throw new Errors.SessionSerializationError("Invalid session data: no fields");
    }

    serializer.version = parseInt(values.shift());
    const version = VERSIONS[serializer.version];

    if (!version) {
      throw new Errors.SessionSerializationError("Unknown version: " + serializer.version);
    }

    if (values.length !== Object.keys(version).length) {
      throw new Errors.SessionSerializationError("Invalid session data: incorrect number of fields");
    }

    try {
      for (let key in version) {
        let valSerializer = version[key];
        let v = values.shift();
        if (v.length) {
          serializer[key] = valSerializer.deserialize(v);
        }
      }
    } catch(err) {
      throw new Errors.SessionSerializationError("Invalid session data: " + err.message);
    }

    return serializer;
  }

  static encode(str) {
    const pass1 = encodeURIComponent(str);

    // encodeURIComponent ignores some characters that should be escaped according to RFC3986
    return pass1.replace(/[!'()*]/g, c => "%" + c.charCodeAt(0).toString(16))
  }

  static decode(str) {
    return decodeURIComponent(str);
  }
}

export default SessionSerializer;