<template>
  <div>
    <h1 class="has-text-centered title is-5">Availability</h1>

    <ul v-if="availableSamples.length > 0">
      <li v-for="s in availableSamples" :key="s.id" class="numeric">
        <app-tabular-text :value="s.label"></app-tabular-text>
      </li>
    </ul>
  </div>
</template>

<script>

  import { mapActions, mapMutations, mapState } from "vuex";

  import AppLoading from "App/components/AppLoading";
  import AppTabularText from "App/components/AppTabularText";

  export default {
    computed: {
      ...mapState([
        "allSamples"
      ]),
      ...mapState("Metadata", [
        "variable"
      ]),

      availableSamples() {
        if (this.variable !== null && this.allSamples !== null) {
          const lookup = new Set(this.variable.sample_ids);
          return this.allSamples.filter(s => lookup.has(s.id));
        } else {
          return [];
        }
      }
    },

    methods: {
      ...mapActions([
        "getSamples"
      ]),
    },

    created() {
      this.loadResource(
        this.getSamples()
      );
    },

    components: {
      AppLoading,
      AppTabularText
    }
  }

</script>