
import api from "App/lib/Api";

export function defaultState() {
  return {
    variable: null,
    variableCodes: null,
    variableComparability: null,
    variableUniverse: null,
    variableQuestionnaire: null,
    variableFlags: null,
    variableEditing: null,
    sample: null
  };
}

export default {
  namespaced: true,
  state: defaultState(),

  getters: {

  },

  mutations: {
    setVariable(state, variable) {
      state.variable = variable;
    },

    setSample(state, sample) {
      state.sample = sample;
    },

    setVariableCodes(state, data) {
      state.variableCodes = data;
    },

    setVariableComparability(state, data) {
      state.variableComparability = data;
    },

    setVariableUniverse(state, data) {
      state.variableUniverse = data;
    },

    setVariableFlags(state, data) {
      state.variableFlags = data;
    },

    setVariableEditing(state, data) {
      state.variableEditing = data;
    },

    setVariableQuestionnaire(state, data) {
      state.variableQuestionnaire = data;
    }
  },

  actions: {
    getVariable({commit, state}, variableIdOrMnemonic) {
      if (state.variable && (state.variable.id === parseInt(variableIdOrMnemonic) || state.variable.mnemonic === variableIdOrMnemonic)) {
        return Promise.resolve(state.variable);
      }

      commit("setVariable", null);

      return api.variable(variableIdOrMnemonic)
        .then(v => {
          commit("setVariable", v);
          return v;
        });
    },

    getVariableCodes({commit, state}, variableId) {
      if (state.variableCodes !== null && state.variableCodes.variable_id === variableId) {
        return Promise.resolve(state.variableCodes);
      }

      return api.variableCodes(variableId)
        .then(data => commit("setVariableCodes", data));
    },

    getVariableComparability({commit, state}, variableId) {
      if (state.variableComparability !== null && state.variableComparability.variable_id === variableId) {
        return Promise.resolve(state.variableComparability);
      }

      return api.variableComparability(variableId)
        .then(data => commit("setVariableComparability", data));
    },

    getVariableUniverse({commit, state}, variableId) {
      if (state.variableUniverse !== null && state.variableUniverse.variable_id === variableId) {
        return Promise.resolve(state.variableUniverse);
      }

      return api.variableUniverse(variableId)
        .then(data => commit("setVariableUniverse", data));
    },

    getVariableFlags({commit, state}, variableId) {
      if (state.variableFlags !== null && state.variableFlags.variable_id === variableId) {
        return Promise.resolve(state.variableFlags);
      }

      return api.variableFlags(variableId)
        .then(data => commit("setVariableFlags", data));
    },

    getVariableEditing({commit, state}, variableId) {
      if (state.variableEditing !== null && state.variableEditing.variable_id === variableId) {
        return Promise.resolve(state.variableEditing);
      }

      return api.variableEditing(variableId)
        .then(data => commit("setVariableEditing", data));
    },

    getVariableQuestionnaire({commit, state}, {variableId, sampleId}) {
      if (state.variableQuestionnaire !== null && state.variableQuestionnaire.variable_id === variableId && state.variableQuestionnaire.sample_id === sampleId) {
        return Promise.resolve(state.variableQuestionnaire);
      }

      return api.variableQuestionnaire(variableId, sampleId)
        .then(data => commit("setVariableQuestionnaire", data));
    },

    getSample({commit, state}, sampleId) {
      if (state.sample && state.sample.id === sampleId) {
        return Promise.resolve(state.sample);
      }

      return api.sample(sampleId)
        .then(s => commit("setSample", s));
    }
  }
}