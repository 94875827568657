<template>
  <div :class="alertClasses">
    <button type="button" class="delete" @click="disableAlert"></button>
    {{alert.message}}
  </div>
</template>

<script>

  import { mapActions } from "vuex";

  export default {
    props: {
      alert: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        alertTimeout: 5000
      }
    },

    mounted() {
      setTimeout(this.disableAlert, this.alertTimeout);
    },

    computed: {
      alertClasses() {
        return "notification is-danger";
      }
    },

    methods: {
      ...mapActions([
        'removeAlert'
      ]),
      disableAlert() {
        this.removeAlert(this.alert.id);
      }
    }
  }

</script>

<style lang="scss" scoped>

  div.notification {
    padding: 0.5rem 1.5rem 0.5rem 0.25rem;

    &:not(:last-child) {
      margin-bottom: 0.25rem;
    }
  }

</style>