import SessionSerializer from "App/lib/SessionSerializer";
import intersection from "lodash/intersection";

export default {
  isLoading(state) {
    return state.loadingCount > 0;
  },
  canSelectVariable(state, getters) {
    return state.selectedVariables.length < 2;
  },
  canSelectSample(state, getters) {
    return state.selectedSamples.length < 2;
  },
  isVariableSelected(state, getters) {
    return v => {
      return state.selectedVariables.findIndex(sv => sv.id === v.id) >= 0;
    };
  },
  isSampleSelected(state, getters) {
    return s => {
      return (s === null && state.selectedSamples.length === 0 || (state.selectedSamples.findIndex(ss => ss.id === s.id) >= 0));
    };
  },
  isVariableAvailable(state, getters) {
    return v => intersection(v.sample_ids, state.selectedSamples.map(s => s.id)).length === state.selectedSamples.length;
  },
  isSampleAvailable(state) {
    const varSamps = new Set(intersection(...state.selectedVariables.map(v => v.sample_ids)));

    return s => {
      if (state.selectedVariables.length === 0) {
        return true;
      } else if (s === null) {
        return false;
      } else {
        return varSamps.has(s.id);
      }
    };
  },
  subpopulationVariables(state) {
    return state.selectedSubpopulations.map(s => s.variable);
  },
  subpopulationForVariable(state, getters) {
    return v => {
      return state.selectedSubpopulations.find(sp => sp.variable.id === v.id) || null;
    };
  },
  isVariableSubpopulation(state, getters) {
    return v => {
      return getters.subpopulationForVariable(v) !== null;
    };
  },
  visibleAlerts(state) {
    return state.alerts.filter(a => a.visible);
  },
  serializedSession(state) {
    return SessionSerializer.fromState(state).serialize();
    // return {
    //   v: state.selectedVariables.map(v => v.id),
    //   s: state.selectedSamples.map(s => s.id),
    //   p: state.selectedSubpopulations.map(s => ({ v: s.variable.id, c: s.selectedCategories.map(c => c.id)})),
    //   g: state.dataDisplayOptions.showGraphs,
    //   m: state.dataDisplayOptions.measure.value
    // }
  }
}