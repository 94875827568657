
let idCounter = 0;

export default class Alert {
  constructor(msg) {
    this.message = msg;
    this.visible = true;

    idCounter = idCounter + 1;
    this.id = idCounter;
  }
}