import Vue from 'vue';
import api from 'App/lib/Api';

export function defaultState() {
  return {
    variableGroups: null,
    hasVariableGroups: false,
    variableCache: null,
    availabilityState: null
  };
}

export default {

  namespaced: true,

  state: defaultState(),

  getters: {
    variablesForGroup(state) {
      return gId => {
        if (state.variableCache) {
          return state.variableCache[gId];
        } else {
          return null;
        }
      }
    }
  },



  mutations: {
    setVariableGroups(state, payload) {
      state.variableGroups = payload.variableGroups;
      state.hasVariableGroups = true;
      state.variableCache = {};
      state.availabilityState = payload.availabilityState;
    },

    setVariablesForGroup(state, payload) {
      if (state.variableCache === null) {
        state.variableCache = {};
      }
      Vue.set(state.variableCache, payload.variableGroupId, payload.variables);
    }
  },

  actions: {
    getVariableGroups({state, rootState, rootGetters, commit}) {
      const availabilityState = rootState.selectedSamples.map(s => s.id).join("||");
      if (!state.hasVariableGroups || (state.availabilityState !== availabilityState)) {
        return api.getVariableGroups(rootState.selectedSamples.map(s => s.id)).then(val => {
          commit("setVariableGroups", {variableGroups: val, availabilityState: availabilityState});
        })
      } else {
        return Promise.resolve(state.variableGroups)
      }
    },

    getVariablesForGroup({state, commit}, variableGroupId) {
      if (state.variableCache[variableGroupId]) {
        return Promise.resolve(state.variableCache[variableGroupId]);
      } else {
        return api.getVariablesByGroup(variableGroupId).then(val => {
          commit("setVariablesForGroup", { variableGroupId: variableGroupId, variables: val });
        });
      }
    }
  }

}