<template>
  <label class="label toggle" :class="icon" @click.prevent="handleClick">
    <app-icon class="icon" :icon="value ? 'toggleOn' : 'toggleOff'" :size="size"></app-icon>
    <slot></slot>
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: "lg"
    }
  },
  computed: {
    icon() {
      if (this.value) {
        return "on";
      } else {
        return "off";
      }
    },
  },
  methods: {
    handleClick() {
      this.$emit('toggle');
      this.$emit("input", !this.value);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~App/styles/variables";
  .toggle {
    text-decoration: none;
    cursor: pointer;
  }

  .toggle.on .icon {
    color: $green;
  }

  .toggle.off .icon {
    color: $grey-darker;
  }

</style>
