<template>
  <div>
    <app-return-header :to="{name: 'tabulator'}">
      Filter Tabulation
    </app-return-header>

    <div class="buttons">
      <router-link :to="{name: 'filter', params: {variableId: v.id}}" v-for="v in selectedVariables" :key="v.id" class="button" :class="{'is-primary': v.id === variableId}">
        {{ v.mnemonic }}
      </router-link>
    </div>

    <div v-if="currentVariable !== null">
      {{ currentVariable.mnemonic }} - {{ currentVariable.label }}
    </div>

    <div @click="toggleGeneralDetailed">
      <app-toggle-switch :value="detailedCategories"></app-toggle-switch>
      Use detailed codes
    </div>

    <div v-if="categories !== null">
      <div class="list">
        <div class="list-item checked" @click="selectAllClick">
          <span class="list-label">
            <app-checkbox :value="selectAllChecked"></app-checkbox>
            Select All
          </span>
        </div>
        <category-item
            v-for="cat in visibleCategories"
            :key="cat.id"
            :category="cat"
            :show-general-label="!detailedCategories"
            :is-selected="isCategorySelected(cat)"
            @toggleSelection="toggleSelection">
        </category-item>
      </div>
    </div>
    <app-loading v-if="localLoading"></app-loading>

  </div>
</template>

<script>

  import { mapState } from "vuex";
  import api from "App/lib/Api";

  import AppCheckbox from "App/components/AppCheckbox";
  import AppLoading from "App/components/AppLoading";
  import AppReturnHeader from "App/components/AppReturnHeader";
  import AppToggleSwitch from "App/components/AppToggleSwitch";
  import CategoryItem from "App/components/CategoryItem";

  export default {
    data() {
      return {
        variable: null,
        categories: null,
        detailedCategories: false
      };
    },

    computed: {
      ...mapState([
        "selectedVariables"
      ]),

      variableId() {
        if (this.$route.params.variableId) {
          return parseInt(this.$route.params.variableId);
        } else {
          return null;
        }
      },

      visibleCategories() {
        return this.categories.filter(c => {
          return c.is_general || this.detailedCategories;
        });
      },

      currentVariable() {
        return this.selectedVariables.find(v => v.id === this.variableId) || null;
      },

      selectAllChecked() {
        return false;
      }
    },

    methods: {
      isCategorySelected(cat) {
        return false;
      },

      toggleSelection(cat) {
        console.log("toggle " + cat.label);
      },

      selectAllClick() {
        console.log("Select all");
      },

      toggleGeneralDetailed() {
        this.detailedCategories = !this.detailedCategories;
      }
    },

    watch: {
      variableId: {
        handler: function() {
          this.variable = null;
          this.categories = null;

          if (this.variableId !== null) {
            this.loadResource(
              api.getVariableWithCategories(this.variableId)
                .then(data => {
                  this.variable = data.variable;
                  this.categories = data.categories;
                })
            )
          }
        },
        immediate: true
      }
    },

    mounted() {
      if (this.selectedVariables.length === 0) {
        this.$router.replace({name: 'tabulator'});
      } else if (this.variableId === null) {
        this.$router.replace({name: 'filter', params: {variableId: this.selectedVariables[0].id}});
      }
    },

    components: {
      AppCheckbox,
      AppReturnHeader,
      AppLoading,
      AppToggleSwitch,
      CategoryItem
    }
  }

</script>

<style lang="scss" scoped>

</style>