<template>
  <div class="section has-text-white">
    <div class="container">
      <h1 class="title is-3 has-text-danger">
        An Unexpected Error Has Occurred
      </h1>
      <h3 class="subtitle is-5 has-text-white">
        Our developers have been notified.  We apologize for the inconvenience.
      </h3>

      <div class="buttons">
        <a href="#" class="button is-primary" @click="retry">Retry</a>

        <a :href="restoreLink" class="button is-primary">Reload Session</a>

        <a :href="refreshLink" class="button is-primary">Start Over</a>
      </div>

    </div>
  </div>
</template>

<script>

  import api from "App/lib/Api";

  export default {
    computed: {
      message() {
        return this.$store.state.errorMessage;
      },

      refreshLink() {
        return api.url("");
      },

      restoreLink() {
        const serializedSession = this.$store.getters.serializedSession;
        const l = window.location;
        return l.protocol + "//" + l.host + `/session?session=${serializedSession}`;
      }
    },

    methods: {
      retry() {
        this.$store.commit("clearError");
      }
    }
  }

</script>

<style lang="scss" scoped>
  @import "~App/styles/variables";

  .container {
    color: white;
    text-align: center;
  }

</style>
