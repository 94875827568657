<template>
  <div>
    <h1 class="has-text-centered title is-5">Data Quality Flags</h1>

    <ul v-if="!localLoading && variableFlags !== null && variableFlags.flags.length > 0">
      <li v-for="f in variableFlags.flags" :key="f.id">
        <router-link :to="{ name: 'metadata_variable_description', params: { id: f.mnemonic } }">{{f.mnemonic}}</router-link>
      </li>
    </ul>

    <div v-if="variableFlags !== null && variableFlags.flags.length === 0">
      No Flags
    </div>

    <app-loading v-if="localLoading"></app-loading>
  </div>
</template>

<script>

  import { mapActions, mapState } from "vuex";

  import AppLoading from "App/components/AppLoading";

  export default {
    props: {
      variableId: {
        required: true,
        type: Number
      }
    },

    computed: {
      ...mapState("Metadata", [
        "variable",
        "variableFlags"
      ])
    },

    methods: {
      ...mapActions("Metadata", [
        "getVariableFlags"
      ])
    },

    watch: {
      variableId: {
        handler: function() {
          this.loadResource(
            this.getVariableFlags(this.variableId)
          );
        },
        immediate: true
      }
    },

    components: {
      AppLoading
    }
  }

</script>