<template>
  <div>
    <h1 class="title is-5 has-text-centered">Comparability</h1>

    <div class="content" v-if="!localLoading" v-external-links v-html="variableComparability.comparability"></div>
  </div>
</template>

<script>

  import { mapActions, mapState } from "vuex";

  import AppLoading from "App/components/AppLoading";

  export default {
    props: {
      variableId: {
        required: true,
        type: Number
      }
    },

    computed: {
      ...mapState("Metadata", [
        "variable",
        "variableComparability"
      ])
    },

    methods: {
      ...mapActions("Metadata", [
        "getVariableComparability"
      ])
    },

    watch: {
      variableId: {
        handler: function() {
          this.loadResource(
            this.getVariableComparability(this.variableId)
          );
        },
        immediate: true
      }
    },

    components: {
      AppLoading
    }
  }

</script>