<template>

  <nav class="level is-mobile crosstab-menu" v-show="shouldDisplay">

    <div class="level-item menu-item">
      <p class="heading">Graph</p>
      <app-toggle-switch :value="showGraphs" size="lg" @toggle="setShowGraphOption(!showGraphs)"></app-toggle-switch>
    </div>

    <div class="level-item menu-item">
      <p class="heading"><span v-if="expandIsCompress">Collapse</span><span v-else>Expand</span></p>
      <app-icon-button :disabled="!expandEnabled" :icon="expandIsCompress ? 'collapse' : 'expand'" size="lg" @click="expand"></app-icon-button>
    </div>

    <div class="level-item menu-item" :class="{disabled: !hasTwoVars}">
      <p class="heading">Flip</p>
      <app-icon-button :disabled="!hasTwoVars" @click="swapVariables" size="lg" icon="flip"></app-icon-button>
    </div>

    <div class="level-item menu-item">
      <p class="heading">Measure</p>
        <app-modal-dropdown :button-class="buttonClasses.concat(['is-small-mobile'])" :button-style="measureStyle" :options="availableMeasures" :value="measure" valueAttribute="value" :labelAttribute="measureLabelAttr" @optionSelected="setMeasure">
      </app-modal-dropdown>
    </div>

    <div class="level-item menu-item">
      <p class="heading">Share</p>
      <app-icon-button @click="openShareModal" icon="share" size="lg"></app-icon-button>

      <app-popup title="Share this data" :open="showShareModal" @dismiss="showShareModal = false">
        <label class="label">Share Link</label>
        <div class="field has-addons">
          <div class="control is-expanded">
            <input type="text" readonly :value="shareLink" class="input" ref="shareTextbox" />
          </div>
          <div class="control">
            <button type="button" class="button" title="Copy Link into Clipboard" v-clipboard:copy="shareLink">
              <span class="icon is-medium">
                <app-icon size="lg" icon="clipboard"></app-icon>
              </span>
            </button>
          </div>
        </div>
      </app-popup>
    </div>

  </nav>

</template>

<script>

  import AppIconButton from "App/components/AppIconButton";
  import AppToggleSwitch from "App/components/AppToggleSwitch";
  import AppModalDropdown from "App/components/AppModalDropdown";
  import AppPopup from "App/components/AppPopup";
  import AppSvg from "App/components/AppSvg";
  import { mapGetters, mapState, mapMutations } from 'vuex';
  import {recordTabulationMenuAction} from "App/lib/Analytics";

  export default {
    data() {
      return {
        showShareModal: false
      }
    },

    computed: {
      ...mapGetters([
        'serializedSession'
      ]),
      ...mapState({
        showGraphs: state => state.dataDisplayOptions.showGraphs,
        availableMeasures: state => state.dataDisplayOptions.availableMeasures,
        measure: state => state.dataDisplayOptions.measure,
        mediaQueries: state => state.mediaQueries,
        hasTwoVars: state => state.selectedVariables.length === 2,
        crossTabData: state => state.crossTabData
      }),
      shouldDisplay() {
        return this.crossTabData != null;
      },
      buttonClasses() {
        return ["button", "is-white"];
      },
      shareLink() {
        const serializedSession = this.serializedSession;
        const l = window.location;
        return l.protocol + "//" + l.host + `/session?session=${serializedSession}`;
      },
      measureLabelAttr() {
        if (this.mediaQueries.mobile) {
          return 'shortName';
        } else {
          return 'name';
        }
      },
      measureStyle() {
        if (this.mediaQueries.mobile) {
          return {padding: '2px'};
        } else {
          return {};
        }
      },
      expandEnabled() {
        return this.crossTabData !== null && this.hasTwoVars;
      },
      expandIsCompress() {
        if (this.crossTabData !== null) {
          return this.crossTabData.findIndex(c => c.show) >= 0;
        } else {
          return false;
        }
      }
    },

    methods: {
      ...mapMutations([
        'setShowGraphOption',
        'setMeasureOption',
        'swapVariables',
        'setCrossTabCollapse'
      ]),
      setMeasure(val) {
        this.setMeasureOption(val);
      },
      openShareModal() {
        recordTabulationMenuAction("share");
        this.showShareModal = true;
        setTimeout(() => {
          this.$refs.shareTextbox.focus();
          this.$refs.shareTextbox.select();
        });
      },
      expand() {
        if (this.crossTabData === null) {
          return;
        }

        const collapse = this.expandIsCompress;
        this.setCrossTabCollapse(collapse);
      }
    },

    components: {
      AppToggleSwitch,
      AppModalDropdown,
      AppPopup,
      AppSvg,
      AppIconButton
    }
  }

</script>

<style lang="scss" scoped>
  @import "~App/styles/variables";
  @import "~bulma/sass/utilities/mixins";

  nav.crosstab-menu {
    align-items: flex-start;

    @include mobile {
      .heading {
        font-size: 0.6rem;
      }
    }
  }

  .level-item.menu-item {
    flex-direction: column;
    margin-right: 0.25rem;

    &:last-child {
      margin-right: 0;
    }

    p {
      margin: 2px;
      float: none;
    }
  }

</style>