<template>

  <div>

    <h3 class="group-title is-clearfix" :class="isOpen ? 'open' : 'closed'" v-if="independentCounts.category !== null" @click.prevent="headerClick">
      {{independentCounts.category.label}}
      <app-arrow-button class="is-pulled-right" :direction="isOpen ? 'down' : 'right'"></app-arrow-button>
    </h3>

    <collapse-transition>
      <div v-if="isOpen">
        <cross-tab-count v-for="count in independentCounts.dependentCounts" :key="count.code" :dependentCounts="count"></cross-tab-count>
      </div>
    </collapse-transition>
  </div>

</template>

<script>

  import { mapMutations } from "vuex";
  import AppArrowButton from 'App/components/AppArrowButton';
  import CrossTabCount from "App/components/CrossTabCount";
  import CollapseTransition from 'App/components/CollapseTransition';

  export default {
    props: {
      independentCounts: {
        required: true,
        type: Object
      }
    },

    computed: {
      isOpen() {
        return this.independentCounts.show;
      }
    },

    methods: {
      ...mapMutations([
        "setCrossTabItemCollapse"
      ]),
      headerClick() {
        this.setCrossTabItemCollapse({code: this.independentCounts.code, isCollapsed: this.independentCounts.show});
      }
    },

    components: {
      AppArrowButton,
      CrossTabCount,
      CollapseTransition
    }
  }

</script>

<style lang="scss" scoped>
  @import "~App/styles/variables";

  .group-title {
    background-color: $grey-darker;
    color: $white;
    font-weight: bold;
    padding-left: 3px;
    margin-bottom: 0.25rem;
    cursor: pointer;
  }

</style>
