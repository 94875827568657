<template>

  <div class="counts">
    <label class="countLabel">{{dependentCounts.category.label}}</label>
    <div v-for="(item, index) in barItems" :key="item.sample.id" class="bar-wrapper">
      <div class="bar" :class="'sample-color-' + (sampleIndex(item.sample) + 1)" :style="{ width: item.barWidth }">
        <app-tabular-text :value="item.display"></app-tabular-text>
      </div>
    </div>
  </div>

</template>

<script>

  import AppTabularText from "App/components/AppTabularText";
  import { mapState } from 'vuex';

  export default {
    props: {
      dependentCounts: {
        type: Object,
        required: true
      }
    },

    computed: {
      ...mapState({
        fillBars: state => state.dataDisplayOptions.showGraphs,
        measure: state => state.dataDisplayOptions.measure.value,
        selectedSamples: state => state.selectedSamples
      }),

      barItems() {
        const items = [];
        for (let count of this.dependentCounts.sampleCounts) {
          items.push({sample: count.sample, barWidth: this.fillBars ? count.weightedPercent + "%" : "0px", display: this.getText(count)});
        }
        return items;
      }
    },

    methods: {
      getText(count) {
        let val = "";
        if (this.measure === "percent") {
          if (count.weightedPercent < 0.1) {
            val = "< 0.1%";
          } else {
            val = this.round(count.weightedPercent) + "%";
          }
        } else if (this.measure === "count") {
          val = count.count.toLocaleString('en');
        } else if (this.measure === "weighted-count") {
          val = count.weightedCount.toLocaleString('en');
        } else {
          val =  "??";
        }

        return count.sample.label + ": " + val;
      },

      round(val) {
        const places = 1;
        const factor = 10 * places;
        let n = Math.round(val * factor);
        let d = n % factor;
        return Math.floor(n / factor).toString() + "." + d.toString();
      },

      sampleIndex(sample) {
        return this.selectedSamples.findIndex(s => s.id === sample.id);
      }
    },

    components: {
      AppTabularText
    }
  }

</script>

<style lang="scss" scoped>
  @import "~App/styles/variables";

  .counts {
    background-color: $white-ter;
    margin-bottom: 3px;
    padding: 3px;
  }

  .bar-wrapper {
    padding-bottom: 5px;
  }

  .bar {
    overflow: visible;
    white-space: nowrap;
    transition: width .25s ease;
    margin-left: 3px;
    position: relative;
    left: -3px;
  }

  .countLabel {
    font-weight: bold;
    color: $black;
  }

</style>
