<template>
  <div class="state-search">

    <app-sticky-container>
      <app-return-header :to="{ name: 'tabulator' }">
        Select Place
      </app-return-header>

      <div class="selection">
        <div v-if="selectedPlace != null">
          <search-states-state-item :place="selectedPlace" class=""></search-states-state-item>
        </div>
      </div>

    </app-sticky-container>

    <div class="search">

      <div class="control has-icons-left">
        <app-autocomplete
            ref="autocomplete"
            v-model="searchText"
            :minLength="2"
            valueAttribute="name"
            @optionSelected="searchItemSelected"
            :searchOptions="allPlaces"
        >
        </app-autocomplete>

        <span class="is-left">
          <app-icon size="sm" icon="search"></app-icon>
        </span>
      </div>

    </div>

    <div class="list">
      <search-states-state-item
          v-for="place in allPlaces"
          :key="place.value"
          :place="place"
          >
      </search-states-state-item>
    </div>

  </div>
</template>

<script>

  import AppToggleSwitch from 'App/components/AppToggleSwitch';
  import SearchStatesStateItem from 'App/components/SearchStatesStateItem.vue';
  import AppReturnHeader from "App/components/AppReturnHeader";
  import AppStickyContainer from 'App/components/AppStickyContainer';
  import AppAutocomplete from 'App/components/AppAutocomplete';
  import { mapActions, mapMutations, mapState } from 'vuex';

  export default {
    data() {
      return {
        searchText: ""
      }
    },
    created() {
      this.loadResource(this.getPlaces());
    },

    computed: {
      ...mapState({
          selectedPlace: state => state.selectedPlace,
          allPlaces: state => state.allPlaces
      })
    },

    methods: {
      ...mapActions([
        'getPlaces'
      ]),
      searchItemSelected(state) {
        this.$store.dispatch("addPlace", state);
        this.searchText = "";
      }
    },

    components: {
      AppToggleSwitch,
      AppReturnHeader,
      SearchStatesStateItem,
      AppAutocomplete,
      AppStickyContainer
    }
  }

</script>

<style lang="scss" scoped>
  @import "~App/styles/variables";

  .selectedState {
    border-bottom: 2px solid $green-dark;
  }

  .search, .options, .list {
    margin-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }

</style>
