<template>

  <div :class="{ unavailable: !isAvailable, checked: isSelected, unselectable: (!canSelect && !isSelected) }" class="variable list-item">

    <app-checkbox class="list-checkbox" :value="isSelected" @toggle="toggleSelect"></app-checkbox>

    <div class="list-label">
    {{variable.mnemonic}} - {{variable.label}}
    </div>

    <app-metadata-link @click.native.stop type="variable" :id="variable.id" class="metadata-link" :label="variable.mnemonic + ' metadata'">
      <app-icon icon="file"></app-icon>
    </app-metadata-link>

  </div>

</template>

<script>

  import { mapState } from 'vuex';
  import AppCheckbox from "App/components/AppCheckbox";
  import AppMetadataLink from "App/components/AppMetadataLink";

  export default {
    props: {
      variable: {
        type: Object,
        required: true
      }
    },
    computed: {
      isAvailable() {
        return this.$store.getters.isVariableAvailable(this.variable);
      },
      isSelected() {
        return this.$store.getters.isVariableSelected(this.variable);
      },
      canSelect() {
        return this.$store.getters.canSelectVariable;
      },
    },
    methods: {
      toggleSelect() {
        this.$emit("click", this.variable);
      }
    },

    components: {
      AppCheckbox,
      AppMetadataLink
    }
  }

</script>

<style lang="scss" scoped>
  @import "~App/styles/variables";

  .check.hide {
    visibility: hidden;
  }

</style>
