<template>
  <div>
    <div class="toprow">
      <div class="selection-item has-text-centered">
        <label class="selection is-size-7-mobile"><strong>Variables</strong> <em>({{varCountText}})</em></label>
        <app-router-button :to="{name: 'select_variables'}" :class="variableButtonClass" :height="buttonHeight">
          <span v-html="variableButtonText"></span>
        </app-router-button>
      </div>

      <div class="selection-item has-text-centered">
        <label class="selection is-size-7-mobile"><strong>Place</strong></label>
        <app-router-button :to="{name: 'place_search'}" class="button is-primary selection is-small-mobile" :height="buttonHeight">
          <span v-html="placeButtonText"></span>
        </app-router-button >
      </div>

      <div class="selection-item has-text-centered">
        <label class="selection is-size-7-mobile"><strong>Time</strong> <em>({{sampleCountText}})</em></label>
        <app-router-button  :to="{name: 'sample_search'}" :class="sampleButtonClass" :height="buttonHeight">
          <span v-html="sampleButtonText"></span>
        </app-router-button >
      </div>
    </div>

    <div class="bottomrow has-text-centered">
      <label class="selection is-size-7-mobile"><strong>Subpopulation</strong> <em>(optional)</em></label>
      <app-router-button :to="{name: 'select_subpopulation'}" :class="subpopButtonClass" :height="buttonHeight">
        <span v-html="subpopButtonText"></span>
      </app-router-button>
    </div>
  </div>

</template>

<script>

  import { mapState } from 'vuex';
  import AppRouterButton from "App/components/AppRouterButton";

  export default {
    computed: {
      ...mapState([
        'selectedSamples',
        'selectedVariables',
        'selectedPlace',
        'selectedSubpopulations'
      ]),
      buttonHeight() {
        return "2.5em";
      },
      sampleButtonText() {
        if (this.selectedSamples.length > 0) {
          return this.selectedSamples.map(s => s.label).join("<br />");
        } else {
          return "ADD";
        }
      },
      variableButtonText() {
        if (this.selectedVariables.length > 0) {
          return this.selectedVariables.map(v => v.mnemonic).join("<br />");
        } else {
          return 'ADD';
        }
      },
      placeButtonText(){
        if(this.$store.state.selectedPlace != null){
          return this.$store.state.selectedPlace.name;
        }
        else{
          return "USA";
        }
      },
      subpopButtonText() {
        if (this.selectedSubpopulations.length === 0) {
          return "ADD";
        } else {
          return this.selectedSubpopulations.map(s => {
            return `${s.variable.mnemonic} (Restricted to ${s.selectedCount} of ${s.count} codes)`
          }).join("<br />");
        }

      },
      variableButtonClass() {
        let selected = this.selectedVariables.length > 0;
        return [
          'selection',
          'is-small-mobile',
          {
            'is-primary': selected,
            'is-secondary': !selected
          }
        ];
      },
      sampleButtonClass() {
        let selected = this.selectedSamples.length > 0;
        return [
          'selection',
          'is-small-mobile',
          {
            'is-primary': selected,
            'is-secondary': !selected
          }
        ];
      },
      subpopButtonClass() {
        const selected = this.selectedSubpopulations.length > 0;
        return [
          'selection',
          'is-small-mobile',
          {
            'is-primary': selected,
            'is-secondary': !selected
          }
        ];
      },

      varCountText() {
        return `${this.selectedVariables.length} of 2`
      },

      sampleCountText() {
        return `${this.selectedSamples.length} of 2`
      }
    },

    components: {
      AppRouterButton
    }
  }

</script>

<style lang="scss" scoped>

  .toprow {
    margin-bottom: 5px;
    display: flex;

    .selection-item {
      flex-basis: 0;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding: 0 0.2rem 0 0.2rem;

      &:first-child {
        padding-left: .5rem;
      }

      &:last-child {
        padding-right: 0.5rem;
      }
    }
  }

  .bottomrow {
    padding: 0 0.5rem 0.25rem 0.5rem;
  }

  .button.selection {
    width: 100%;
    height: auto;
    line-height: 1.25em;
    white-space: normal;
    padding: 2px 2px;
  }

</style>