<template>
  <div class="sticky">
    <div class="stickySpacker" :style="spacerStyle"></div>
    <div class="stickyWrapper" ref="wrapper" :style="wrapperStyle">
      <resize-observer @notify="handleResize" />
      <slot></slot>
    </div>
  </div>
</template>

<script>

  export default {

    props: {
      backgroundColor: {
        required: false,
        type: String,
        default: "white"
      }
    },

    data() {
      return {
        operationQueued: false,
        handlingEvents: false,
        sticky: false,
        originalOffset: 0,
        originalWidth: 0,
        originalHeight: 0
      };
    },

    computed: {
      appElement() {
        return document.getElementById("app");
      },

      spacerStyle() {
        return {
          height: this.originalHeight + "px",
          width: this.originalWidth + "px"
        };
      },

      wrapperStyle() {
        const style = {
          backgroundColor: this.backgroundColor,
          position: "relative"
        };

        if (this.sticky) {
          Object.assign(style, {
            width: this.originalWidth + "px",
            position: "fixed",
            top: 0,
            zIndex: 20
          });
        }

        return style;
      }
    },

    mounted() {
      this.registerEventListeners();
    },

    beforeDestroy() {
      this.disableEventListeners();
    },

    methods: {
      registerEventListeners() {
        this.handlingEvents = true;
        window.addEventListener('scroll', this.handleScroll, true);
        window.addEventListener('resize', this.handleResize, true);
      },

      disableEventListeners() {
        this.handlingEvents = false;
        window.removeEventListener('scroll', this.handleScroll, true);
        window.removeEventListener('resize', this.handleResize, true);
      },

      handleResize(e) {
        this.queueStyleUpdate(true);
      },

      handleScroll(e) {
        this.queueStyleUpdate(false);
      },

      queueStyleUpdate(resetWidth) {
        if (this.handlingEvents !== true)
          return;

        if (!this.operationQueued) {
          window.requestAnimationFrame(() => {
            this.checkScrollPosition(resetWidth);
            this.operationQueued = false;
          });

          this.operationQueued = true;
        }
      },

      checkScrollPosition(resetWidth) {

        const setSticky = () => {
          const wrapperRect = this.$refs.wrapper.getBoundingClientRect();
          const scrollOffset = window.pageYOffset;
          const yDelta = wrapperRect.height - this.originalHeight;
          const yShinkDelta = yDelta < 0 ? yDelta : 0;

          if (this.sticky === false && wrapperRect.top <= 0) {
            this.originalOffset = wrapperRect.top + scrollOffset;
            this.originalWidth = wrapperRect.width;
            this.originalHeight = wrapperRect.height;
            this.sticky = true;
          } else if (this.sticky === true && scrollOffset < (this.originalOffset - yShinkDelta)) {
            if (yShinkDelta > 0) {
              this.appElement.scrollTop = scrollOffset + yShinkDelta;
            }
            this.originalWidth = 0;
            this.originalHeight = 0;
            this.sticky = false;

          }
        };


        if (resetWidth) {
          this.originalWidth = 0;
          this.originalHeight = 0;
          this.sticky = false;
          this.$nextTick(() => {
            setSticky();
          });
        } else {
          setSticky();
        }
      }
    }
  }

</script>

<style lang="scss" scoped>

</style>