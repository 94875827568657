import Vue from 'vue'
import Vuex from 'vuex'
import Metadata from 'App/store/modules/Metadata';
import SearchVariables from 'App/store/modules/SearchVariables';
import actions from 'App/store/actions';
import getters from 'App/store/getters';
import mutations, { allPlacesPlace } from 'App/store/mutations';

Vue.use(Vuex);

const availableMeasures = [
  {value: 'percent', name: 'Percent', shortName: 'Percent'},
  {value: 'weighted-count', name: 'Weighted-count', shortName: 'W-Count'},
  {value: 'count', name: 'Count', shortName: 'Count'},
];

export function defaultState() {
  return {
    hasError: false,
    errorMessage: null,
    errorObj: null,
    loadingCount: 0,

    selectedVariables: [],
    selectedSamples: [],
    selectedPlace: allPlacesPlace,
    selectedSubpopulations: [],
    selectionErrors: [],

    hasPlaces: false,
    allPlaces: [allPlacesPlace],
    allSamples: null,

    crossTabData: null,
    dataDisplayOptions: {
      showGraphs: true,
      availableMeasures: availableMeasures,
      measure: availableMeasures[0]
    },

    alerts: []
  };
}

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    Metadata,
    SearchVariables
  },
  state: defaultState(),
  getters,
  mutations,
  actions
});

// Support HMR during development
if (module.hot) {
  // accept actions and mutations as hot modules
  module.hot.accept(["./actions", "./getters", "./mutations", "./modules/SearchVariables", "./modules/Metadata"], () => {
    // require the updated modules
    // have to add .default here due to babel 6 module output
    const newSearchVariables = require('./modules/SearchVariables').default;
    const newMetadata = require('./modules/Metadata').default;
    const newActions = require('./actions').default;
    const newGetters = require('./getters').default;
    const newMutations = require('./mutations').default;

    // swap in the new actions and mutations
    store.hotUpdate({
      modules: {
        Metadata: newMetadata,
        SearchVariables: newSearchVariables
      },
      actions: newActions,
      getters: newGetters,
      mutations: newMutations
    })
  })
}