import "App/styles";
import "vue-resize/dist/vue-resize";

import Vue from "vue"
import { sync } from "vuex-router-sync";
import responsiveSync from "App/lib/VuexResponsiveSync";
import VueClipboard from "vue-clipboard2";
import VueResize from "vue-resize";
import config from "App/lib/Config";
import "App/lib/Fontawesome";
import "App/lib/VueMixins";
import "App/directives/ExternalLinks";
import store from "App/store";
import router from "App/router";
import App from "App/components/App";
import AppIcon from "App/components/AppIcon";

Vue.use(VueClipboard);
Vue.use(VueResize);
sync(store, router);
responsiveSync(store);
Vue.component("AppIcon", AppIcon);

document.addEventListener("DOMContentLoaded", () => {

  const app = document.getElementById("app");
  config.baseApiUrl = app.dataset.url;
  config.baseIpumsUrl = app.dataset.ipums_url;
  config.isIOS = !!(navigator && navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform));

  window.$vm = new Vue({
    el: "#app",
    store,
    router,
    render: createElement => createElement(App),
    mounted() {
      window.document.body.classList.remove("loading");
    }
  });
});
