<template>
  <search-variables :variable-item-component="variableComponent" :selected-variables="selectedVariables" @variableClick="variableClick"></search-variables>
</template>

<script>

  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
  import SearchVariables from "App/components/SearchVariables";
  import SelectVariablesVariableItem from 'App/components/SelectVariablesVariableItem';

  export default {
    data() {
      return {
        variableComponent: SelectVariablesVariableItem
      };
    },

    computed: {
      ...mapGetters([
        "canSelectVariable",
        "isVariableSelected"
      ]),
      ...mapState([
        "selectedVariables"
      ])
    },

    methods: {
      ...mapActions([
        "addAlert",
        "selectVariable",
        "removeVariable"
      ]),

      variableClick(variable) {
        if (!this.isVariableSelected(variable)) {
          if (this.canSelectVariable) {
            this.selectVariable(variable);
          } else {
            this.addAlert({message: 'You may only select 2 variables'});
          }
        } else {
          this.removeVariable(variable);
        }
      }
    },

    components: {
      SearchVariables
    }
  }

</script>

<style lang="scss" scoped>
</style>