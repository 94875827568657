<template>

  <div class="has-text-centered">
    <div class="svg-wrapper" :class="size">
      <app-svg icon="loader"></app-svg>
    </div>
    <slot></slot>
  </div>

</template>

<script>

  import TWEEN from '@tweenjs/tween.js';
  import AppSvg from "App/components/AppSvg";

  // In SVG units
  const BEAD_DELTA = 30;

  // In milliseconds
  const BEAD_MOVE_DURATION = 300;

  class Bead {
    constructor(el, startsRight) {
      this.el = el;
      this.startsRight = startsRight;
      this.internalPosition = 0;
    }

    get position() {
      return this.internalPosition;
    }

    set position(value) {
      this.internalPosition = value;
      this.update();
    }

    update() {
      let pxDelta = (this.position / 100) * BEAD_DELTA;
      if (this.startsRight) {
        pxDelta = 0 - pxDelta;
      }

      this.el.style.transform = `translate(${pxDelta}px, 0)`;
    }
  }

  export default {
    props: {
      size: {
        required: false,
        type: String,
        default: 'md',
        validator: (v) => ['md', 'lg'].indexOf(v) >= 0
      }
    },

    data() {
      return {
        animation: null
      }
    },

    computed: {
      iconSize() {
        let size = Math.max(0, Math.floor(this.size));
        size = Math.min(5, size);

        if (size > 1) {
          return size.toString() + "x";
        } else if (size === 1) {
          return "lg";
        } else {
          return null;
        }
      }
    },

    mounted() {
      const topBead = new Bead(this.$el.querySelector("path.bead-top"), true);
      const middleBead = new Bead(this.$el.querySelector("path.bead-middle"), false);
      const bottomBead = new Bead(this.$el.querySelector("path.bead-bottom"), true);

      if (topBead.el && middleBead.el && bottomBead.el) {
        const animSteps = [
          new TWEEN.Tween(topBead).to({position: 100}, BEAD_MOVE_DURATION),
          new TWEEN.Tween(middleBead).to({position: 100}, BEAD_MOVE_DURATION),
          new TWEEN.Tween(bottomBead).to({position: 100}, BEAD_MOVE_DURATION),
          new TWEEN.Tween(topBead).to({position: 0}, BEAD_MOVE_DURATION),
          new TWEEN.Tween(middleBead).to({position: 0}, BEAD_MOVE_DURATION),
          new TWEEN.Tween(bottomBead).to({position: 0}, BEAD_MOVE_DURATION)
        ];

        for (let x = 0; x < animSteps.length; x++) {
          let nextIdx = (x + 1) % animSteps.length;
          animSteps[x]
            .easing(TWEEN.Easing.Circular.In)
            .chain(animSteps[nextIdx]);
        }

        this.animation = animSteps[0];
        this.animation.start();
      }
    },

    beforeDestroy() {
      if (this.animation !== null) {
        this.animation.stop();
        this.animation = null;
      }
    },

    components: {
      AppSvg
    }
  }

</script>

<style lang="scss" scoped>
  @import "~App/styles/variables";

  div.svg-wrapper {
    display: inline-block;
    color: $black-ter;

    &.md {
      width: 30px;
      height: 30px;
    }

    &.lg {
      width: 100px;
      height: 100px;
    }
  }

</style>