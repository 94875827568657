<template>

  <div :class="{ checked: isSelected }" class="variable list-item" @click="toggleSelect">

    <app-checkbox class="list-checkbox" :class="{check: true, hide: (!canSelect && !isSelected)}" :value="isSelected"></app-checkbox>

    <div class="list-label">
      {{variable.mnemonic}} - {{variable.label}}
    </div>

    <router-link @click.native.stop class="edit-link tag" v-if="isSelected" :to="{name: 'edit_subpopulation', params: { variableId: variable.id }}">
      {{ subpopulation.selectedCategories.length }} of {{ subpopulation.categories.length }} <app-icon icon="pencil"></app-icon>
    </router-link>

    <span class="metadata-link">
      <app-metadata-link @click.native.stop type="variable" :id="variable.id">
        <app-icon icon="file"></app-icon>
      </app-metadata-link>
    </span>

  </div>

</template>

<script>

  import { mapGetters, mapState } from 'vuex';
  import AppCheckbox from "App/components/AppCheckbox";
  import AppIcon from "App/components/AppIcon";
  import AppMetadataLink from "App/components/AppMetadataLink";

  export default {
    props: {
      variable: {
        type: Object,
        required: true
      }
    },
    computed: {
      ...mapGetters([
        "isVariableSubpopulation",
        "subpopulationForVariable"
      ]),

      subpopulation() {
        return this.subpopulationForVariable(this.variable);
      },

      isSelected() {
        return this.isVariableSubpopulation(this.variable);
      },

      canSelect() {
        return true;
      },
    },
    methods: {
      toggleSelect() {
        this.$emit("click", this.variable);
      }
    },

    components: {
      AppCheckbox,
      AppIcon,
      AppMetadataLink
    }
  }

</script>

<style lang="scss" scoped>
  @import "~App/styles/variables";

  .edit-link {
    color: $green !important;
    background-color: $white !important;
  }

  .check.hide {
    visibility: hidden;
  }

</style>
