<template>

  <div class="crosstab">
    <div v-for="e in selectionErrors" :key="e">
      {{e}}
    </div>

    <app-loading v-if="loading" size="lg">
      <br/><span>Loading...</span>
    </app-loading>

    <div v-if="error" class="has-text-centered">
      <app-icon class="has-text-danger" icon="exclamation" size="2xl"></app-icon><br/>
      <h1 class="title is-4">An unexpected error occurred while tabulating data.</h1>
      <h3 class="title is-6">
        Our developers have been notified.<br/>We apologize for the inconvenience.
      </h3>
    </div>

    <div v-if="hasData">
      <div class="legend">
        <span v-for="(v, idx) in selectedVariables">
          <span v-if="idx > 0"><br/><em>by </em></span>
          <app-metadata-link type="variable" :id="v.mnemonic">{{v.label}}</app-metadata-link>
        </span>
        <app-icon icon="flip" size="sm" class="flip btn" v-if="selectedVariables.length > 1" @click.native="swapVariables"></app-icon>
        <span v-if="showExpandedLegend">
          <br/>
          <span v-if="selectedPlace"><em>in </em>{{ selectedPlace.name }}</span>
          <em>for </em>
          <span v-for="(s, idx) in selectedSamples">
            <span v-if="idx > 0"><em> & </em></span>
            <app-metadata-link type="sample" :id="s.id">{{s.label}}</app-metadata-link>
          </span>
          <span v-if="selectedSubpopulations.length > 0">
            <br/>
            Restricted to
            <span v-for="(p, idx) in selectedSubpopulations">
              <span v-if="idx > 0"><em> & </em></span>
              <app-metadata-link type="variable" :id="p.variable.mnemonic">{{p.variable.mnemonic}}</app-metadata-link>
              [<router-link :to="{ name: 'edit_subpopulation', params: {variableId: p.variable.id} }" v-for="(c, cidx) in p.selectedCategories" :key="c.id"><span v-if="cidx > 0">, </span>{{c.label}}</router-link>]
            </span>
          </span>
        </span>
        <app-icon :icon="collapseIcon" size="sm" class="expand" @click.native="showExpandedLegend = !showExpandedLegend"></app-icon>
      </div>

      <div v-if="selectedSamples.length > 2" class="sample-legend">
        <div v-for="(sample, index) in selectedSamples" :key="sample.id" class="sample">
          <span class="sample-color-box" :class="'sample-color-' + (index + 1)"></span>
          {{ sample.label }}
        </div>
      </div>

      <cross-tab-count-group v-for="group in crossTabData" :key="group.key" :independentCounts="group"></cross-tab-count-group>

    </div>
  </div>

</template>

<script>

  import { mapActions, mapMutations, mapState } from 'vuex';
  import AppLoading from 'App/components/AppLoading';
  import AppSvg from 'App/components/AppSvg';
  import CrossTabCountGroup from "App/components/CrossTabCountGroup";
  import AppMetadataLink from "./AppMetadataLink";

  export default {
    data() {
      return {
        showExpandedLegend: true,
        loading: false,
        error: null
      }
    },

    computed: {
      ...mapState([
        'selectedPlace',
        'selectedSamples',
        'selectedSubpopulations',
        'selectedVariables',
        'selectionErrors',
        'crossTabData'
      ]),

      hasData() {
        return this.loading === false && this.error === null && this.selectedSamples.length > 0 && this.selectedVariables.length > 0;
      },

      collapseIcon() {
        if (this.showExpandedLegend) {
          return "titleCollapse";
        } else {
          return "titleExpand";
        }
      }
    },

    mounted() {
      this.updateData();
    },

    watch: {
      crossTabData() {
        this.updateData();
      }
    },

    methods: {
      ...mapActions([
        'getCrossTabData'
      ]),
      ...mapMutations([
        'swapVariables'
      ]),

      toggleLegenExpand() {
        this.showExpandedLegend = !this.showExpandedLegend;
      },

      updateData() {
        this.error = null;
        if (this.crossTabData === null) {
          this.loading = true;
          this.getCrossTabData()
            .catch(err => {
              this.error = err;
              console.log(err);
            })
            .then(() => this.loading = false);
        }
      }
    },

    components: {
      AppMetadataLink,
      CrossTabCountGroup,
      AppLoading,
      AppSvg
    }
  }

</script>

<style lang="scss" scoped>
  @import "~App/styles/variables";

  .crosstab {
    margin-left: 5px;
    margin-right: 5px;
  }

  .legend {
    .icon {
      border-radius: 10px;
    }

    .flip {
      background-color: $green;
      color: white;
    }

    .expand {
      color: white;
      width: 25px;
    }
  }

  .sample-legend {

    background-color: $black-ter;
    margin-bottom: 0.25rem;

    .sample {
      display: inline-flex;
      align-items: center;
      padding-right: 0.5rem;
      padding-left: 0.25rem;
      color: $white;
    }

    .sample-color-box {
      display: inline-flex;
      margin-right: 0.125rem;
      height: 1rem;
      width: 1rem;
    }
  }

</style>