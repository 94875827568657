import api from 'App/lib/Api'
import * as Errors from 'App/lib/Errors';
import { recordException, recordTabulation } from "App/lib/Analytics";
import { parseCrosstabs } from 'App/lib/CrossTabData';
import SessionSerializer from "App/lib/SessionSerializer";
import Subpopulation from "App/lib/Subpopulation";
import Alert from "App/lib/Alert";

export default {
  selectVariable({commit, dispatch, getters, state}, variable) {
    return new Promise(function(resolve, reject) {
      if (!getters.canSelectVariable) {
        reject(new Errors.SelectionError("Cannot select more than 2 variables"));
      } else if (getters.isVariableSelected(variable)) {
        reject(new Errors.SelectionError("Cannot select the same variable again"))
      } else {
        commit("addSelectedVariable", variable);
        dispatch("validateSelection").then(() => resolve());
      }
    });
  },

  removeVariable({commit, dispatch, getters, state}, variable) {
    return new Promise(function(resolve, reject) {

      if (getters.isVariableSelected(variable)) {
        commit("removeSelectedVariable", variable);
        dispatch("validateSelection").then(() => resolve());
      } else {
        reject(new Errors.SelectionError("The variable [" + variable ? variable.id : "UNKNOWN" + "] is not selected"));
      }
    });
  },

  selectSample({commit, dispatch, getters, state}, sample) {
    return new Promise(function(resolve, reject) {
      if (!getters.canSelectSample) {
        reject(new Errors.SelectionError("Cannot select more than 2 samples"));
      } else if (getters.isSampleSelected(sample)) {
        reject(new Errors.SelectionError("Cannot select the same sample again"));
      } else {
        commit("addSelectedSample", sample);
        dispatch("validateSelection").then(() => resolve());
      }
    });
  },

  removeSample({commit, dispatch, getters}, sample) {
    return new Promise(function(resolve, reject) {

      if (getters.isSampleSelected(sample)) {
        commit("removeSelectedSample", sample);
        dispatch("validateSelection").then(() => resolve());
      } else {
        reject(new Errors.SelectionError("The sample [" + sample ? sample.id : "UNKNOWN" + "] is not selected"));
      }
    });
  },

  addPlace({commit, dispatch, getters}, place){
    commit("setSelectedPlace", place);
    return dispatch("validateSelection");
  },

  removePlace({commit, dispatch, getters, state}){
    commit("setSelectedPlace", state.allPlaces[0]);
    return dispatch("validateSelection");
  },

  addSubpopulation({commit, dispatch}, subpop) {
    commit("addSelectedSubpopulation", subpop);
    return dispatch("validateSelection");
  },

  removeSubpopulation({commit, dispatch}, subpop) {
    commit("removeSelectedSubpopulation", subpop);
    return dispatch("validateSelection");
  },

  getPlaces({commit, state}) {
    if (state.hasPlaces === false) {
      return api.getPlaces().then(places => {
        commit("setPlaces", state.allPlaces.concat(places));
        return state.allPlaces;
      });
    }
    return Promise.resolve(state.allPlaces);
  },

  getSamples({commit, state}) {
    if (state.allSamples === null) {
      return api.getSamples().then(samples => commit("setSamples", samples));
    } else {
      return Promise.resolve(state.allSamples);
    }
  },

  getCrossTabData({commit, state, getters}) {
    if (state.selectedVariables.length > 0 && state.selectionErrors.length === 0) {
      return api.crossTabs(state.selectedVariables.map(v => v.id), state.selectedSamples.map(s => s.id), state.selectedPlace, state.selectedSubpopulations)
        .then(data => {
          recordTabulation(state.selectedVariables, state.selectedSamples, state.selectedPlace, state.selectedSubpopulations);
          commit("setCrossTabData", parseCrosstabs(state.selectedVariables, state.selectedSamples, data))
        });
    } else {
      // noop
      return Promise.resolve();
    }
  },

  validateSelection({commit, state, getters}) {
    const errors = [];

    if (state.selectedVariables.length === 0) {
      errors.push("No Variables selected");
    }

    if (state.selectedSamples.length === 0) {
      errors.push("No Times selected");
    }

    state.selectedVariables.concat(getters.subpopulationVariables).forEach(v => {
      if (!getters.isVariableAvailable(v)) {
        errors.push(`Variable ${v.mnemonic} is not available in all selected Times`);
      }
    });

    commit("setSelectionErrors", errors);
    return Promise.resolve();
  },

  restoreSession({commit, dispatch, state}, data) {
    return new Promise(function(resolve, reject) {
      const sessionSerializer = SessionSerializer.fromSerializedData(data);

      commit("clearSelection");
      commit("setShowGraphOption", sessionSerializer.showGraphs);
      commit("setMeasureOption", state.dataDisplayOptions.availableMeasures.find(av => sessionSerializer.measure === av.value));

      const loadPromises = [];

      const varIds = sessionSerializer.variableIds;
      const samIds = sessionSerializer.sampleIds;
      const subpops = sessionSerializer.subpopulationIds;
      const placeId = sessionSerializer.placeId;

      loadPromises.push(Promise.all(varIds.map(v => api.variable(v)))
        .then(vars => vars.forEach(v => commit("addSelectedVariable", v))));

      loadPromises.push(Promise.all(samIds.map(s => api.sample(s)))
        .then(samps => samps.forEach(s => commit("addSelectedSample", s))));

      if (placeId !== null) {
        loadPromises.push(dispatch("getPlaces").then(places => {
          commit("setSelectedPlace", places.find(p => p.id === placeId));
        }));
      }

      loadPromises.push(Promise.all(subpops.map(s => api.getVariableWithCategories(s.variableId)
        .then(data => {
          let subp = new Subpopulation(data.variable, data.categories);
          s.categoryIds.forEach(cid => subp.addCategory(cid));
          commit("addSelectedSubpopulation", subp);
        }))));

      resolve(Promise.all(loadPromises).then(() => dispatch("validateSelection")));
    });
  },

  addAlert({commit}, payload) {
    let alert = new Alert(payload.message);
    commit("addAlert", alert);
  },

  removeAlert({commit}, alertId) {
    commit("disableAlert", alertId);
  }
}