import { recordException, recordTabulation, recordTabulationMenuAction } from "App/lib/Analytics";

export const allPlacesPlace = {id: -1, name: 'Entire USA'};

export default {
  setError(state, error) {
    if (console)
      console.log(error);

    state.hasError = true;
    state.errorObj = error;

    if (error && error.message) {
      state.errorMessage = error.message;
    } else if (error) {
      state.errorMessage = error.toString();
    } else {
      state.errorMessage = "Unknown Error";
    }

    recordException(state.errorMessage, true);
  },
  clearError(state) {
    state.errorObj = null;
    state.errorMessage = null;
    state.hasError = false;
  },
  setLoading(state, val) {
    if (val === true) {
      state.loadingCount = state.loadingCount + 1;
    } else {
      state.loadingCount = state.loadingCount - 1;
    }
  },
  clearSelection(state) {
    state.selectedVariables = [];
    state.selectedSamples = [];
    state.selectedSubpopulations = [];
    state.selectedPlace = allPlacesPlace;
    state.crossTabData = null;
  },
  addSelectedVariable(state, variable) {
    state.selectedVariables.push(variable);
    state.crossTabData = null;
  },
  removeSelectedVariable(state, variable) {
    state.selectedVariables = state.selectedVariables.filter(v => v.id !== variable.id);
    state.crossTabData = null;
  },
  swapVariables(state) {
    state.selectedVariables.reverse();
    state.crossTabData = null;
    recordTabulationMenuAction("flip");
  },
  addSelectedSample(state, sample) {
    state.selectedSamples.push(sample);
    state.selectedSamples.sort((a, b) => {
      if (a.year < b.year) return -1;
      else if (a.year > b.year) return 1;
      else return 0;
    });
    state.crossTabData = null;
  },
  removeSelectedSample(state, sample) {
    state.selectedSamples = state.selectedSamples.filter(s => s.id !== sample.id);
    state.crossTabData = null;
  },
  setSelectedPlace(state, place) {
    state.selectedPlace = place || allPlacesPlace;
    state.crossTabData = null;
  },
  addSelectedSubpopulation(state, subpop) {
    let idx = state.selectedSubpopulations.findIndex(s => s.variable.id === subpop.variable.id);
    if (idx >= 0) {
      state.selectedSubpopulations.splice(idx, 1, subpop);
    } else {
      state.selectedSubpopulations.push(subpop);
    }
    state.crossTabData = null;
  },
  removeSelectedSubpopulation(state, subpop) {
    let idx = state.selectedSubpopulations.findIndex(s => s.variable.id === subpop.variable.id);
    if (idx >= 0) {
      state.selectedSubpopulations.splice(idx, 1);
    }
    state.crossTabData = null;
  },
  setSelectionErrors(state, errs) {
    state.selectionErrors = errs;
  },
  setPlaces(state, val){
    state.hasPlaces = true;
    state.allPlaces = val
  },
  setSamples(state, samples) {
    state.allSamples = samples;
  },
  setCrossTabData(state, data) {
    state.crossTabData = data;
  },
  setShowGraphOption(state, data) {
    state.dataDisplayOptions.showGraphs = data;
    recordTabulationMenuAction("graph");
  },
  setMeasureOption(state, data) {
    state.dataDisplayOptions.measure = data;
    recordTabulationMenuAction("measure");
  },
  addAlert(state, alert) {
    state.alerts.push(alert);
  },
  disableAlert(state, alertId) {
    let a = state.alerts.find(a => a.id === alertId);
    if (a) {
      a.visible = false;
    }
  },
  setCrossTabCollapse(state, isCollapsed) {
    if (state.crossTabData === null) { return; }
    for (let c of state.crossTabData) {
      c.show = !isCollapsed;
    }
    recordTabulationMenuAction("collapse");
  },
  setCrossTabItemCollapse(state, payload) {
    if (state.crossTabData === null) { return; }
    const cti = state.crossTabData.find(c => c.code === payload.code);
    if (cti) {
      cti.show = !payload.isCollapsed;
    }
  },
  resetCrossTabData(state) {
    state.crossTabData = null;
  },
  fullStateReset(state, newState) {
    for (let key in newState) {
      state[key] = newState[key];
    }
  }
}
