import config from 'App/lib/Config';
import * as Errors from 'App/lib/Errors';

class Api {
  constructor() {
  }

  baseUrl() { return config.baseApiUrl; }

  url(path) {
    return this.baseUrl() + path;
  }

  checkStatus(response) {
    if (response.status === 204) {
      return null;
    } else if (response.ok) {
      return response.json();
    } else if (response.status === 404) {
      throw new Errors.ApiNotFoundError(response.statusText, response);
    } else {
      throw new Errors.ApiServerError(response.statusText || "Unknown Server Error", response);
    }
  }

  parseJSON(response) {
    return response.json();
  }

  performRequest(url, method, params = {}) {
    const hasBody = Object.keys(params || {}).length !== 0;

    const headers = new Headers();
    headers.append('Accept', 'application/json');

    const opts = {
      headers,
      method: method
    };

    if (hasBody) {
      headers.append('Content-Type', 'application/json');
      opts.body = JSON.stringify(params);
    }

    return fetch(url, opts).then(this.checkStatus);
  }

  get(url, params = {}) {

    const queryParams = [];

    for (let key in params) {
      const val = params[key];
      if (Array.isArray(val)) {
        for (let x of val) {
          queryParams.push(encodeURIComponent(key) + "[]=" + (x === null ? '' : encodeURIComponent(x)));
        }
      } else {
        queryParams.push(encodeURIComponent(key) + "=" + (val === null ? '' : encodeURIComponent(val)));
      }
    }

    if (queryParams.length) {
      url = url + "?" + queryParams.join("&");
    }

    return this.performRequest(url, "GET");
  }

  post(url, params = {}) {
    return this.performRequest(url, "POST", params);
  }

  getVariableGroups(sampleIds) {
    return this.get(this.url("api/abacus/v1/metadata/variable_groups"), {sample_ids: sampleIds});
  }

  getVariablesByGroup(groupId) {
    return this.get(this.url("api/abacus/v1/metadata/variables_by_group"), {variable_group: groupId});
  }

  getSamples() {
    return this.get(this.url("api/abacus/v1/metadata/samples"));
  }

  getPlaces() {
    return this.get(this.url("api/abacus/v1/metadata/get_states"))
  }

  variableSearch(sampleIds, searchText) {
    let query = {
      sample_ids: sampleIds,
      query: searchText
    };

    return this.get(this.url("api/abacus/v1/metadata/variable_search.json"), query);
  }

  variable(idOrMnemonic) {
    return this.get(this.url("api/abacus/v1/metadata/variable/" + idOrMnemonic));
  }

  sample(id) {
    return this.get(this.url("api/abacus/v1/metadata/sample/" + id));
  }

  getVariableWithCategories(variableId) {
    return this.get(this.url("api/abacus/v1/metadata/variable_categories/" + variableId));
  }

  crossTabs(variableIds, sampleIds, place, subpopulations) {

    let subpops = [];

    if (subpopulations && subpopulations.length > 0) {
      subpops = subpopulations.map(s => {
        return {
          variable_id: s.variable.id,
          category_ids: s.selectedCategories.map(c => c.id)
        };
      });
    }

    const query = {
      variable_ids: variableIds,
      sample_ids: sampleIds,
      state: place,
      subpopulations: subpops
    };

    return this.post(this.url("api/abacus/v1/data/cross_tabs"), query);
  }

  variableCodes(variableId) {
    const query = {
      variable_id: variableId
    };

    return this.get(this.url("api/abacus/v1/metadata/variable_codes"), query);
  }

  variableComparability(variableId) {
    const query = {
      variable_id: variableId
    };

    return this.get(this.url("api/abacus/v1/metadata/variable_comparability"), query);
  }

  variableUniverse(variableId) {
    const query = {
      variable_id: variableId
    };

    return this.get(this.url("api/abacus/v1/metadata/variable_universe"), query);
  }

  variableQuestionnaire(variableId, sampleId) {
    const query = {
      variable_id: variableId,
      sample_id: sampleId
    };

    return this.get(this.url("api/abacus/v1/metadata/variable_questionnaire"), query);
  }

  variableFlags(variableId) {
    const query = {
      variable_id: variableId
    };

    return this.get(this.url("api/abacus/v1/metadata/variable_flags"), query);
  }

  variableEditing(variableId) {
    const query = {
      variable_id: variableId
    };

    return this.get(this.url("api/abacus/v1/metadata/variable_editing"), query);
  }

  postReportError(error_params) {
    const params = {
      error: error_params
    };

    return this.post(this.url("report_error"), params);
  }
}

const api = new Api();

export default api;
