<template>
  <section ref="app" id="app" class="section">
    <vue-top-progress ref="progress"></vue-top-progress>
    <router-view v-if="$store.state.hasError !== true"></router-view>
    <the-error-page v-else></the-error-page>
    <app-alerts></app-alerts>

    <button v-if="false" id="makeError" style="position: absolute; right: 0; bottom: 0;" @click="setTimeout(() => { throw new Error('this is a manual error') })">Error!</button>
  </section>
</template>

<script>

  import TheErrorPage from 'App/components/TheErrorPage.vue';
  import AppAlerts from "App/components/AppAlerts";
  import { vueTopprogress } from 'vue-top-progress';
  import api from 'App/lib/Api';
  import BrowserFullscreen from 'App/lib/BrowserFullscreen';
  import Config from 'App/lib/Config';
  import TWEEN from '@tweenjs/tween.js';

  export default {
    name: 'app',

    data() {
      return {
        scrollData: {
          allowUp: false,
          allowDown: false,
          slideBeginY: 0
        }
      }
    },

    mounted() {

      // Setup global animation loop
      function animate () {
        TWEEN.update();
        requestAnimationFrame(animate);
      }
      animate();

      this.$watch("isLoading", function(newVal, oldVal) {
        if (newVal) {
          this.$refs.progress.start();
        } else {
          this.$refs.progress.done();
        }
      });

      this.$watch("$store.state.hasError", function(newVal, oldVal) {
        if (newVal) {
          this.$refs.progress.fail();
          this.reportError(this.$store.state.errorObj);
        } else {
          this.$refs.progress.done();
        }
      });

      let component = this;

      window.onerror = function(message, source, lineno, colno, error) {
        if (!error) {
          error = {
            name: 'Error',
            message: message,
            fileName: source,
            lineNumber: lineno,
            columnNumber: colno
          };
        }

        // Ignore these errors
        if (message.toString().match(/Failed to read the 'cssRules' property from 'CSSStyleSheet': Cannot access rules/i)) {
          return;
        }

        component.setError(error);
      };

      window.addEventListener("keydown", function(event) {
        component.appKey(event);
      });

      // Disable iOS "bouncy" scroll behavior
      // (found to be buggy; disabled.
      // if (Config.isIOS) {
      //   document.documentElement.addEventListener('touchstart', function(event) {
      //     console.log(this);
      //     console.log(this.scrollTop);
      //     console.log(this.scrollHeight);
      //     console.log(this.clientHeight);
      //     component.scrollData.allowUp = (this.scrollTop > 0);
      //     component.scrollData.allowDown = (this.scrollTop < this.scrollHeight - this.clientHeight);
      //     component.scrollData.slideBeginY = event.pageY;
      //     console.log(component.scrollData);
      //   });
      //
      //   document.documentElement.addEventListener('touchmove', function(event) {
      //     const up = (event.pageY > component.scrollData.slideBeginY);
      //     const down = (event.pageY < component.scrollData.slideBeginY);
      //     component.scrollData.slideBeginY = event.pageY;
      //     if ((up && component.scrollData.allowUp) || (down && component.scrollData.allowDown)) {
      //       event.stopPropagation();
      //     }
      //     else {
      //       event.preventDefault();
      //     }
      //   });
      // }
    },

    methods: {
      reportError(err) {
        if (!err) {
          return;
        }

        try {
          api.postReportError({
            name: err.name || "Error",
            message: (err.message) ? err.message : err.toString(),
            stack: (err.stack && err.stack.split) ? err.stack.split("\n").filter(l => l.length > 0) : null,
            fileName: err.fileName || null,
            lineNumber: err.lineNumber || null,
            columnNumber: err.columnNumber || null
          })
        } catch(err) {
          // noop
        }
      },

      appKey(evt) {
        if (evt) {
          let target = evt.target;
          let key = evt.key;
          let isAlt = evt.altKey;
          let targetTag = target ? target.tagName.toUpperCase() : null;
          if (targetTag !== "TEXTAREA" && targetTag !== "INPUT") {
            if (key === "f" || key === "F") {
              //BrowserFullscreen.toggleFullscreen();
            } else if (isAlt && key === "r" || key === "R") {
              this.$store.commit("resetCrossTabData");
            }
          }
        }
      }
    },

    components: {
      AppAlerts,
      'the-error-page': TheErrorPage,
      'vue-top-progress': vueTopprogress
    }
  }

</script>

<style lang="scss">
  @import "~App/styles/variables";
</style>
