import Vue from 'vue';
import { mapGetters, mapMutations, mapState } from 'vuex';

Vue.mixin({
  data() {
    return {
      localLoadingCount: 0
    };
  },
  computed: {
    ...mapGetters([
      "isLoading"
    ]),
    localLoading() {
      return this.localLoadingCount > 0;
    }
  },
  methods: {
    ...mapMutations([
      'setError',
      'setLoading'
    ]),

    loadResource(promise) {
      this.setLoading(true);
      this.localLoadingCount = this.localLoadingCount + 1;

      return promise
        .catch(err => this.setError(err))
        .then(res => {
          this.setLoading(false);
          this.localLoadingCount = this.localLoadingCount - 1;
          return res;
        });
    }
  }
});
