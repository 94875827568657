import Vue from 'vue';
import Router from 'vue-router';
import { recordRouteChange } from "App/lib/Analytics";

import TheSplashPage from 'App/components/TheSplashPage';
import TheHomePage from 'App/components/TheHomePage';
import TheBaseLayout from 'App/components/TheBaseLayout';
import SelectSubpopulation from "App/components/SelectSubpopulation";
import SelectSubpopulationEdit from "App/components/SelectSubpopulationEdit";
import SelectVariables from 'App/components/SelectVariables';
import SearchSamples from 'App/components/SearchSamples';
import FilterTabulation from 'App/components/FilterTabulation';
import The404Page from 'App/components/The404Page';
import SearchStates from 'App/components/SearchStates.vue';
import TheRestoreSessionPage from 'App/components/TheRestoreSessionPage';

import TheMetadataVariableLayout from 'App/components/TheMetadataVariableLayout';
import TheMetadataSampleLayout from 'App/components/TheMetadataSampleLayout';
import MetadataVariableAvailability from 'App/components/MetadataVariableAvailability';
import MetadataVariableCodes from 'App/components/MetadataVariableCodes';
import MetadataVariableComparability from 'App/components/MetadataVariableComparability';
import MetadataVariableDescription from 'App/components/MetadataVariableDescription';
import MetadataVariableEditing from 'App/components/MetadataVariableEditing';
import MetadataVariableFlags from 'App/components/MetadataVariableFlags';
import MetadataVariableQuestionnaireText from 'App/components/MetadataVariableQuestionnaireText';
import MetadataVariableUniverse from 'App/components/MetadataVariableUniverse';


Vue.use(Router);

const router = new Router({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.name !== from.name) {
      return { x: 0, y: 0 };
    } else {
      return null;
    }
  },

  routes: [
    {
      path: '/',
      name: 'splash',
      component: TheSplashPage
    },
    {
      path: '/session',
      name: 'restore_session',
      component: TheRestoreSessionPage
    },
    {
      path: '/tabulator',
      component: TheBaseLayout,
      children: [
        {name: 'tabulator', path: '', component: TheHomePage},
        {name: 'select_variables', path: 'select_variables', component: SelectVariables},
        {name: 'select_subpopulation', path: 'select_subpopulation', component: SelectSubpopulation },
        {name: 'edit_subpopulation', path: 'edit_subpopulation/:variableId(\\d+)', component: SelectSubpopulationEdit },
        {name: 'sample_search', path: 'sample_search', component: SearchSamples},
        {name: 'place_search', path: 'place_search', component: SearchStates},
        {name: 'filter', path: 'filter/:variableId(\\d+)?', component: FilterTabulation}
      ]
    },
    {
      path: '/metadata',
      component: TheBaseLayout,
      children: [
        {
          path: 'variable/:id',
          component: TheMetadataVariableLayout,
          children: [
            {path: '', redirect: {name: 'metadata_variable_description'}},
            {name: 'metadata_variable_description', path: 'description', component: MetadataVariableDescription},
            {name: 'metadata_variable_codes', path: 'codes', component: MetadataVariableCodes},
            {name: 'metadata_variable_comparability', path: 'comparability', component: MetadataVariableComparability},
            {name: 'metadata_variable_universe', path: 'universe', component: MetadataVariableUniverse},
            {name: 'metadata_variable_questionnaire_text', path: 'questionnaire_text', component: MetadataVariableQuestionnaireText},
            {name: 'metadata_variable_flags', path: 'flags', component: MetadataVariableFlags},
            {name: 'metadata_variable_availability', path: 'availability', component: MetadataVariableAvailability},
            {name: 'metadata_variable_editing', path: 'editing', component: MetadataVariableEditing}
          ]
        },
        {
          path: 'sample/:id(\\d+)',
          name: 'metadata_sample',
          component: TheMetadataSampleLayout
        }
      ]
    },
    {
      path: '*',
      component: The404Page
    }
  ]
});

router.afterEach((to, from) => {
  recordRouteChange(to.fullPath);
});

export default router;