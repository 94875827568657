<template>
  <search-variables
      :variable-item-component="variableComponent"
      :selected-variables="subpopulationVariables"
      title-text="Select Variable(s) for Subpopulation"
      :available-toggle="false"
      @variableClick="variableClick">
  </search-variables>
</template>

<script>

  import { mapActions, mapGetters, mapState } from 'vuex';
  import SearchVariables from "App/components/SearchVariables";
  import SelectSubpopulationVariableItem from 'App/components/SelectSubpopulationVariableItem';

  export default {
    data() {
      return {
        variableComponent: SelectSubpopulationVariableItem
      };
    },

    computed: {
      ...mapGetters([
        "subpopulationVariables",
        "subpopulationForVariable",
        "isVariableSubpopulation"
      ])
    },

    methods: {
      ...mapActions([
        "addAlert",
        "removeSubpopulation"
      ]),

      variableClick(variable) {
        if (this.isVariableSubpopulation(variable)) {
          this.removeSubpopulation(this.subpopulationForVariable(variable));
        } else {
          this.$router.push({name: 'edit_subpopulation', params: { variableId: variable.id }});
        }
      }
    },

    components: {
      SearchVariables
    }
  }

</script>

<style lang="scss" scoped>
</style>