<template>
  <div class="sample-search">

    <app-sticky-container>

      <app-return-header :to="{ name: 'tabulator' }">
        Select Time
      </app-return-header>

      <div class="selection">
        <fade-transition-group>
          <div v-for="sample in selectedSamples" :key="sample.id">
            <search-samples-sample-item :sample="sample" class="selectedSample" @sampleClick="toggleSampleSelection"></search-samples-sample-item>
          </div>
        </fade-transition-group>
      </div>

    </app-sticky-container>

    <div class="search">

      <div class="control has-icons-left">
        <app-autocomplete
            v-model="searchText"
            :minLength="2"
            valueAttribute="name"
            labelAttribute="year"
            placeholder="SEARCH"
            @optionSelected="searchItemSelected"
            :onGetOptions="updateSearchItems"
        >
        </app-autocomplete>

        <span class="is-left">
          <app-icon size="sm" icon="search"></app-icon>
        </span>
      </div>

    </div>

    <div class="options">
      <app-toggle-switch :value="showUnavailable" @toggle="toggleUnavailable"></app-toggle-switch>
      Display {{ unavailableCount }} unavailable samples
    </div>

    <div class="list">
      <app-loading v-if="localLoading"></app-loading>
      <template v-else>
        <search-samples-sample-item
            v-for="sample in visibleSamples"
            :key="sample.id"
            :sample="sample"
            @sampleClick="toggleSampleSelection"
        >
        </search-samples-sample-item>

        <div v-if="visibleSamples.length === 0" class="notification">
          <i>There are no samples available for all currently selected variables</i>
        </div>
      </template>
    </div>

  </div>
</template>

<script>

  import AppAutocomplete from 'App/components/AppAutocomplete';
  import AppLoading from "App/components/AppLoading";
  import AppReturnHeader from "App/components/AppReturnHeader";
  import AppToggleSwitch from 'App/components/AppToggleSwitch';
  import AppStickyContainer from 'App/components/AppStickyContainer';
  import FadeTransitionGroup from 'App/components/FadeTransitionGroup';
  import SearchSamplesSampleItem from 'App/components/SearchSamplesSampleItem.vue';
  import { mapState, mapActions, mapGetters } from 'vuex';
  import api from 'App/lib/Api';

  export default {
    data() {
      return {
        showUnavailable: false,
        searchText: ""
      }
    },
    mounted() {
      this.loadResource(this.getSamples());
    },

    computed: {
      ...mapState({
        selectedSamples: state => state.selectedSamples,
        samples: state => state.allSamples || [],
        hasSamples: state => state.allSamples !== null
      }),
      ...mapGetters([
        'canSelectSample',
        'isSampleAvailable',
        'isSampleSelected'
      ]),
      visibleSamples() {
        if (this.showUnavailable) {
          const sorted = [].concat(this.samples);
          return sorted.sort((a, b) => {
            const aAvail = this.isSampleAvailable(a);
            const bAvail = this.isSampleAvailable(b);

            if (aAvail !== bAvail) {
              if (aAvail === true) {
                return -1;
              } else {
                return 1;
              }
            }

            if (a.label < b.label) {
              return 1;
            } else if (a.label > b.label) {
              return -1;
            }

            return 0;

          });
        } else {
          const samps = [];
          for (let x = 0; x < this.samples.length; x++) {
            if (this.isSampleAvailable(this.samples[x])) {
              samps.push(this.samples[x]);
            }
          }
          return samps;
        }
      },
      unavailableCount() {
        let count = 0;
        for (let x = 0; x < this.samples.length; x++) {
          if (!this.isSampleAvailable(this.samples[x])) {
            count++;
          }
        }
        return count;
      }
    },

    methods: {
      ...mapActions([
        'addAlert',
        'getSamples',
        'selectSample',
        'removeSample'
      ]),
      toggleUnavailable() {
        this.showUnavailable = !this.showUnavailable;
      },
      updateSearchItems(text) {
        const regex = new RegExp('^' + text);
        return Promise.resolve(this.samples.filter(s => this.isSampleAvailable(s) && (s.name.match(regex) || s.year.toString().match(regex))));
      },
      toggleSampleSelection(sample) {
        if (sample) {
          if (this.isSampleSelected(sample)) {
            this.removeSample(sample);
          } else if (!this.canSelectSample) {
            this.addAlert({message: "You may only select 2 samples"});
          } else {
            this.selectSample(sample);
          }
        }
      },
      searchItemSelected(sample) {
        if (!this.canSelectSample) {
          this.addAlert({message: "You may only select 2 samples"});
        } else if (this.isSampleSelected(sample)) {
          this.addAlert({message: "Sample " + sample.name + " already selected"});
        } else {
          this.selectSample(sample)
            .catch(err => this.setError(err));
        }
        this.searchText = "";
      }
    },

    components: {
      AppAutocomplete,
      AppLoading,
      AppReturnHeader,
      AppToggleSwitch,
      AppStickyContainer,
      FadeTransitionGroup,
      SearchSamplesSampleItem
    }
  }

</script>

<style lang="scss" scoped>
  @import "~App/styles/variables";

  .selectedSample {
    border-bottom: 2px solid $green-dark;
  }

  .search, .options, .list {
    margin-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }

</style>
